<script>
import { Bar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
// console.log(Bar);
export default {
  props: {
    chart_data: {
      type: Object,
      required: true
    }
  },
  extends: Bar,
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      this.chart_data,
      /*
      {
        labels: ['Türkiye', 'Almanya'],
        datasets: [
          {
            label: 'Erkek',
            backgroundColor: '#f87979',
            data: [40, 20]
          },
          {
            label: 'Kadın',
            backgroundColor: 'green',
            data: [50, 28]
          }
        ]
      },
      */
      {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: true,
          // custom: CustomTooltips,
          intersect: false,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function(tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    )
  }
}

</script>
