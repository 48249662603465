<script>
import { Line } from 'vue-chartjs'
// import chartjsPluginAnnotation from 'chartjs-plugin-annotation'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'

export default {
  props: {
    chart_data: {
      type: Object,
      required: false
    },
    annotation: {
      type: Object,
      required: false
    }
  },
  components: {
    hexToRgba,
    CustomTooltips
  },
  extends: Line,
  mounted () {
    this.renderChart(
      this.chart_data,
      /*      {
              labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: hexToRgba('#E46651', 90),
                  data: [30, 39, 10, 50, 30, 70, 35]
                },
                {
                  label: 'Data Two',
                  backgroundColor: hexToRgba('#00D8FF', 90),
                  data: [39, 80, 40, 35, 40, 20, 45]
                }
              ]
            },*/
      {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: true,
          // custom: CustomTooltips,
          intersect: false,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        },
        annotation: this.annotation,
        chartArea: {
          backgroundColor: 'rgba(251, 85, 85, 0.4)'
        }
      }
    )
  }
}

</script>

