<template>
  <div>
    <b-card bg-variant="white" no-body :class="[d_mainFullScreen ?'full-screen-mode' :'normal-mode']">
      <b-card-header>
        <b-row>
          <b-col sm="12" lg="6">
            <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Raporlar
          </b-col>
          <b-col sm="12" lg="4">
            <b-button variant="white" @click="f_getReport()">
              <img src="@/icon/665778.png" style="width: 1.5em;"> Raporları getir
            </b-button>
          </b-col>
          <b-col sm="12" lg="2">
            <b-dropdown variant="white" size="md" class="pull-right" right>
              <template slot="button-content">işlemler</template>
              <b-dropdown-item @click="f_clearReport()">raporları temizle yeniden başlat</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card-header>
      <b-tabs>
        <b-tab @click="d_selectedTab = 'index_profile_normal'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color: green'">İndeksleme ve Profilleme</span>
          </template>
          <b-row v-if="d_layerReports.report_list.length > 0">
            <b-col cols="12">
              <analyze-report-view :p_analyzeReports="d_layerReports.report_list"></analyze-report-view>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab @click="d_selectedTab = 'index_profile_bulk'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color: green'">İndeksleme ve Profilleme (Yığın veri)</span>
          </template>
          <b-row v-if="d_layerReports.report_list.length > 0">
            <b-col cols="12">
              <analyze-report-view :p_analyzeReports="d_layerReports.report_list"></analyze-report-view>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab @click="d_selectedTab = 'chart_total_data_count_last_100_data'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color: green'">Tarih bazlı toplam veri adedi (son 100 veri)</span>
          </template>
          <b-row v-if="d_layerReports.report_list.length > 0">
            <b-col cols="12">
              <analyze-report-view :p_analyzeReports="d_layerReports.report_list"></analyze-report-view>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab @click="d_selectedTab = 'layer_active_process_analyze'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color: green'">Aktif indeks analizi</span>
          </template>
          <template v-if="d_layerActiveProcessAnalyze.show">
            <b-row style="margin: 10px;">
              <b-col cols="12">
                <w-table2 :p_tableDetails="d_layerActiveProcessAnalyze"></w-table2>
              </b-col>
            </b-row>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import AnalyzeReportView from '@/components/widgets/AnalyzeReportView';
import {
  default as WTable
} from '@/components/widgets/WTable';

import {
  default as WTable2
} from '@/components/widgets/WTable2';

import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from "moment";
import WTopMenu from '@/components/widgets/WTopMenu';
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import WmanagerService from '@/services/wmanager';
import WdmService from '@/services/wdm';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
  name: 'LayerReport',
  components: {
    Modal,
    WebBottom,
    WTopMenu,
    vSelect,
    WisdomDataModal,
    WdmParameters,
    WTable,
    WTable2,
    AnalyzeReportView
  },
  computed: mapGetters({
    help: 'help',
    device: 'device'
  }),
  data () {
    return {
      d_selectedTab: 'index_profile_normal',
      d_layerReports: { 'report_list': [] },
      d_layerActiveProcessAnalyze: {
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'show': false,
        'timeout': 0,
        'table_header': [],
        'table': [],
        'filtered_table': [],
        'show_columns': [],
        'count': 0,
        'search_text': '',
        'search_index_list': []
      },
      d_timerControlUpdate: { 'timer': 0, 'status': 'passive' },
      d_layerDetails: {
        'sand_watch': false,
        'updated': 0,
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'show': true,
        'timeout': 0,
        'table_header': [],
        'table': [],
        'filtered_count': 0,
        'filtered_table': [],
        'show_columns': [],
        'count': 0,
        'search_text': '',
        'search_index_list': []
      },
      d_mainFullScreen: false,
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    if (this.d_wdm22 && this.d_wdm22.id) {
      /*
      this.d_timerControlUpdate.timer = setInterval(function () {
        // console.log("this.d_project_id ", this.d_project_id);
        if (this.d_timerControlUpdate.status === 'passive') {
          this.f_getLayerList();
        }
      }.bind(this), 30000);
      */
      // this.f_getUserWdmList();
    }
    // this.f_getWdmrTreeList();
    this.d_wdmrToken = JSON.parse(localStorage.getItem('wdmr_token'));
    // this.f_layerReports('normal');
  },
  mounted () {},
  destroyed () {
    // window.clearInterval(this.d_timerControlUpdate.timer);
  },
  methods: {
    f_getReport: function () {
      if (this.d_selectedTab === 'index_profile_normal') {
        this.f_layerReports('normal');
      } else if (this.d_selectedTab === 'index_profile_bulk') {
        this.f_layerReports('fix');
      } else if (this.d_selectedTab === 'chart_total_data_count_last_100_data') {
        this.f_layerReports('', ['chart_total_data_count_last_100_data']);
      } else if (this.d_selectedTab === 'layer_active_process_analyze') {
        this.f_layerActiveProcessAnalyze();
      }
    },
    f_layerReports: function (analyze_type = '', report_type_list = []) {
      this.d_layerReports.report_list = [];
      let query = 'type=' + analyze_type;
      let data = { 'report_type_list': report_type_list };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman raporları hazırlanıyor. Lütfen bekleyiniz' } });
      WmanagerService.layer_report_list(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === "3000") {
            this.d_layerReports.report_list = resp.data.report_list;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_clearReport: function () {
      let cont = true;
      let report_type_list = [];
      let query = '';
      let data = { 'report_type_list': report_type_list };
      if (this.d_selectedTab === 'index_profile_normal') {
        query = 'type=normal';
      } else if (this.d_selectedTab === 'index_profile_bulk') {
        query = 'type=fix';
      } else if (this.d_selectedTab === 'chart_total_data_count_last_100_data') {
        query = '';
        data.report_type_list.push('chart_total_data_count_last_100_data');
      } else {
        cont = false;
        alert('Rapor sayfalarını seçiniz');
      }
      if (cont) {
        this.d_layerReports.report_list = [];
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman raporları temizleniyor. Lütfen bekleyiniz' } });
        WmanagerService.clear_layer_report(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === "3000") {
              alert('işlem tamamlandı.');
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_layerActiveProcessAnalyze: function () {
      this.d_layerActiveProcessAnalyze = {
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'show': false,
        'timeout': 0,
        'table_header': [],
        'table': [],
        'filtered_table': [],
        'show_columns': [],
        'count': 0,
        'search_text': '',
        'search_index_list': []
      };
      let query = 'layer_type=user';
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman aktif işlem analizleri getiriliyor. Lütfen bekleyiniz' } });
      WmanagerService.layer_active_process_analyze(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === "3000") {
            this.d_layerActiveProcessAnalyze.table = resp.data.table;
            this.d_layerActiveProcessAnalyze.table_header = resp.data.table_header;
            this.d_layerActiveProcessAnalyze.count = this.d_layerActiveProcessAnalyze.table.length;
            this.d_layerActiveProcessAnalyze.show = true;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
  },
  watch: {
    'd_selectedTab': function () {
      this.d_layerReports.report_list = [];
    }
  }
};

</script>

<style type="text/css">
#user-search-list-row:hover {
  background-color: #92c9ea;
}

#user-search-list-selected-row:hover {
  background-color: #e29494;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

